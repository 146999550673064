import { Box, Typography } from "@mui/material";
import React from "react";
import './Gallery.css'
import Slider from "react-slick";
import image1 from "../Green Meadows Website Design Assets/Images/1793-Rainbow - Hinjewadi_Cam 017_Party Lawn_01.webp";
import image2 from "../Green Meadows Website Design Assets/Images/1793-Rainbow - Hinjewadi_Cam_007_Row House 01.webp";
import image3 from "../Green Meadows Website Design Assets/Images/1793-Rainbow - Hinjewadi_Cam_012_Pool Night_02.webp";
import image4 from "../Green Meadows Website Design Assets/Images/1793-Rainbow - Hinjewadi_Master Layout_Cam_001_Rev 01.webp";
import image5 from "../Green Meadows Website Design Assets/Images/Hero Image.png"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Gallery = () => {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    lazyLoad: true,
  };
  return (
    <Box sx={{paddingTop: {xs: '100px', sm: 'none'}}} id='gallery'>
      <Box sx={{ position: "relative" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }} id="gallery">
          <Typography variant="h4" className="title">
            Gallery
          </Typography>
        </Box>
        <Typography variant="h1" className="bg-text">
          Gallery
        </Typography>
      </Box>
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <img src={image1} alt="" className="slider-image" />
          </div>
          <div>
            <img src={image2} alt="" className="slider-image" />
          </div>
          <div>
            <img src={image3} alt="" className="slider-image" />
          </div>
          <div>
            <img src={image4} alt="" className="slider-image" />
          </div>
          <div>
            <img src={image5} alt="" className="slider-image slider-image-5" />
          </div>
        </Slider>
      </div>
    </Box>
  );
};

export default Gallery;
