import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import aboutUs from "../Green Meadows Website Design Assets/Images/About Us Image.webp";
import "./Overview.css";
import icon from "../Green Meadows Website Design Assets/Icon/icon-01.png";

const Overview = () => {
  return (
    <Container sx={{ position: "relative", paddingBottom: "50px" }}>
      <Box sx={{ position: "relative" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }} id="overview">
          <Typography variant="h4" className="title">
            Overview
          </Typography>
        </Box>
        <Typography variant="h1" className="bg-text">
          Overview
        </Typography>
      </Box>
      <Grid container columnSpacing={5}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", alignItems: "center", height: "400px" }}
        >
          <Container className="overview-text">
            <Typography mb={5}>
              Discover an oasis of luxury at Green Meadows at Pachane near
              Hinjawadi Pune, where RERA approved NA plots and luxurious row
              houses of prestige await amidst superior quality development.
            </Typography>
            <Typography>
              Immerse yourself in high-class living within a gated 25-acre
              community overlooking the Kasarsai Dam, perfectly situated at just
              15 minutes drive from Hinjawadi.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={aboutUs} alt="" width={"80%"} />
          </Box>
        </Grid>
      </Grid>
      <Box className="icon-1">
        <img src={icon} alt="" width={"10%"} />
      </Box>
      <Box className="icon-2">
        <img src={icon} alt="" width={"35%"} />
      </Box>
    </Container>
  );
};

export default Overview;
