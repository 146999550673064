import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import "./WhyUs.css";
import BirdeyeView from "../Green Meadows Website Design Assets/Images/1793-Rainbow - Hinjewadi_Cam_009_Birdeye_Rev.webp";
import image1 from "../Green Meadows Website Design Assets/Images/9.png";
import image2 from "../Green Meadows Website Design Assets/Images/10.png";
import image3 from "../Green Meadows Website Design Assets/Images/11.png";
import image4 from "../Green Meadows Website Design Assets/Images/12.png";
import icon from '../Green Meadows Website Design Assets/Icon/icon-01.png'


const WhyUs = () => {
  return (
    <Box>
      <Container>
        <Box
          sx={{
            background: "rgba(47, 107, 69, 0.75)",
            width: {xs: '100%', sm: "90%"},
            margin: {xs: 'none', sm: "auto"},
            paddingTop: {xs: '50px', sm: 'none'}
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }} id="about">
              <Typography
                variant="h4"
                className="title"
                sx={{ color: "#fff", borderColor: "#fff" }}
              >
                Why Us
              </Typography>
            </Box>
            <Typography
              variant="h1"
              className="bg-text"
              sx={{ color: "rgba(255, 255, 255, 0.025)" }}
            >
              Why Us
            </Typography>
          </Box>
          <Box
            className="about-text"
            sx={{
              textAlign: "center",
              width: {xs: '90%', sm: "85%"},
              margin: "auto",
              paddingBottom: "60px",
            }}
          >
            <Typography variant="h5" fontSize={{xs: '20px'}}>A Community Like No Other</Typography>
            <Typography>
              At Green Meadows, you're not just buying a home; you're joining a
              vibrant, welcoming community. Our thoughtfully designed spaces
              encourage social interaction, relaxation, and wellness. With
              top-tier amenities and a commitment to preserving nature, we offer
              a living experience that’s truly one of a kind.
            </Typography>
          </Box>
        </Box>
      </Container>

      <Box mt={10} sx={{ position: "relative", height: {xs: '1100px', sm: '800px'} }}>
        <Box>
          <img src={BirdeyeView} alt="" width={"100%"} />
        </Box>
        <Box
          sx={{
            background: "linear-gradient(180deg, #00813D 0%, #085524 100%)",
            width: {xs: '95%', sm: "60%"},
            margin: "auto",
            position: "absolute",
            left: {xs: '2%', sm: "10%"},
            bottom: {xs: '0%',sm: "-10%"},
            zIndex: 1,
          }}
          py={5}
        >
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "40px 0",
              }}
            >
              <Typography
                variant="h4"
                className="title"
                sx={{ color: "#fff", borderColor: "#fff", position: "static" }}
              >
                Discover the Possibilities
              </Typography>
            </Box>
          </Box>
          <Box
            className="about-text"
            sx={{
              textAlign: "center",
              width: "80%",
              margin: "auto",
            }}
          >
            <Typography variant="h5">
              Nature’s Playground at Your Doorstep
            </Typography>
            <Typography>
              Green Meadows offers an unparalleled opportunity to immerse
              yourself in nature’s wonders. With the Kasarsai backwaters and
              Sahyadri mountains just a stone’s throw away, your weekends can be
              filled with exploration and relaxation. Imagine morning jogs along
              scenic trails, fishing by the calm waters, or simply unwinding
              with a book as you take in the stunning views. This is more than
              just a home—it’s your personal gateway to the natural beauty that
              surrounds you.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box pt={{xs: 10, sm: 25}} sx={{position: 'relative'}}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: {xs: '0px', sm: "30px"},
                  }}
                >
                  <Typography
                    variant="h4"
                    className="title"
                    sx={{ position: "static", padding: {xs: '5px 0px', sm: '10px 30px'}, fontSize: {xs: '30px'} }}
                  >
                    Advantage Hinjawadi
                  </Typography>
                </Box>
              </Box>
              <Box
                className="overview-text"
                sx={{
                  textAlign: "start",
                  width: {xs: '100%', sm: "80%"},
                  margin: "auto",
                  paddingBottom: "20px",
                }}
              >
                <Typography variant="h5" mt={5} mb={2}>
                  Close to Hinjawadi, Close to Everything
                </Typography>
                <Typography>
                  Living just 15 minutes away from Hinjawadi means you’re always
                  connected to the heart of Pune’s IT and business hub. Enjoy
                  the perfect balance of work and leisure, with quick commutes
                  and easy access to top schools, shopping centers, and
                  entertainment options. Green Meadows offers you the
                  convenience of city life without the chaos.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex" }} className="about-images">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={image1} alt="" width={"70%"} height={'30%'} />
                  <img src={image3} alt="" width={"70%"} height={'30%'} />
                </Box>
                <Box
                  mt={10}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={image2} alt="" width={"70%"} height={'30%'} />
                  <img src={image4} alt="" width={"70%"} height={'30%'} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className='icon-3'>
            <img src={icon} alt="" width={'10%'}/>
          </Box>
          <Box className='icon-4'>
            <img src={icon} alt="" width={'35%'}/>
          </Box>
          <Box className='icon-5'>
            <img src={icon} alt="" width={'25%'}/>
          </Box>
          <Box className='icon-6'>
            <img src={icon} alt="" width={'10%'}/>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default WhyUs;
